.table {
  max-height: 1800px;
}

.head {
  background-color: lightgray;
  font-weight: bold;
  font-size: large;
  text-align: center;
}

.body {
  text-align: center;
}

.existed {
  background-color: lightyellow;
}

.new {
  background-color: #e8ffe8;
}
