.container {
  display: flex;
  justify-content: center;
}

.modal {
  width: 90%;
  margin-top: 5%;
  background-color: #ebecec;
  border-radius: 5px;
  padding: 2%;
  text-align: center;
}

.logo {
  padding-top: 20px;
  max-width: 200px;
  height: auto;
}

.bannerContainer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
}
