/* ContextMenu.css */
.context-menu {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    padding: 5px;
    list-style: none;
    margin: 0;
  }
  
  .context-menu li {
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .context-menu li:hover {
    background-color: #f0f0f0;
  }
  