.button {
  margin-top: 10px;
}

.itemContainer {
  margin-top: 60px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

.textField {
  width: 400px;
}

.dropBox {
  width: 100%;
  display: flex;
  justify-content: center;
}
